import Validators from '@/modules/Validators';
import store from "../store";

export default function Validate(formRules, form) {
  // Clear errors
  store.dispatch("setErrors", {})
  let validationSuccessfull = true;
  
  // Field names
  const fieldNames = Object.keys(formRules)

  fieldNames.forEach(fieldName => {
    const rules = formRules[fieldName].rules.filter( String );

    if (rules.length > 0) {
      // Validation rules
      const rules = formRules[fieldName].rules

      let formValue = form[fieldName]

      // Gets nested form value
      if (fieldName.includes(".")) {
        const nameArray = fieldName.split( "." )
        let currentValue = form
        nameArray.forEach(name => {
          currentValue = currentValue[name]
        });
        formValue = currentValue
      }
      
      // All validation errors
      const validation = Validators.validate(formValue)
      let errors = []
      
      // Adds errors by rule name
      rules.forEach(rule => {
        if (validation[rule]) {
          errors.push(validation[rule])
        }
      });
      
      errors = errors.filter( String )
      if (errors.length > 0) {
        validationSuccessfull = false
      }
      
      errors = errors.length > 0 ? errors : null

      store.dispatch("addErrors", {name: fieldName, error: errors})
    }
  });

  return validationSuccessfull;
}
