<template>
  <form v-if="availableAdditionalPermissions" class="space-y-6 w-full divide-y divide-gray-200 dark:divide-gray-700">
    <div class="grid grid-cols-2 gap-6 pt-4">

      <template v-for="(permission, index) in availableAdditionalPermissions" :key="permission.id">
        <div class="col-span-1 flex items-center">
          <Checkbox :text="permission.display_name" v-model="selectedPermissions[index]" :value="permission.id" />
        </div>
      </template>

    </div>

    <div class="pt-5">
      <div class="flex justify-end">
        <button @click="hideEditPermissionsForm" type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2">
          Atcelt
        </button>

        <template v-if="!loading">
          <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
            Saglabāt
          </button>
        </template>
        <template v-else>
          <Loading />
        </template>
      </div>
    </div>
  </form>
</template>

<script>
import Checkbox from "@/components/Components/Checkbox";
import { mapGetters } from "vuex";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";

export default {
  name: "EditUserPermissions",
  components: {
    Checkbox,
    Loading,
  },
  props: ["inputData", "userPermissions"],
  data: () => ({
    selectedPermissions: [],
  }),
  created() {
    const selectedIds = this.selectedPermissions = this.userPermissions.direct_permissions.map(
      (permission) => permission.id
    );

    this.selectedPermissions = this.availableAdditionalPermissions.map(
      (permission) => {
        if (selectedIds.includes(permission.id)) {
          return permission.id
        }
        return null
      }
    )
  },
  computed: {
    ...mapGetters({
      loading: "loading",
    }),
    availableAdditionalPermissions() {
      return this.inputData.all_permissions.filter((permission) => {
        return !this.userPermissions.permissions_via_role.some(
          (rolePermission) => {
            return permission.id === rolePermission.id;
          }
        );
      });
    },
  },
  methods: {
    hideEditPermissionsForm() {
      this.$store.dispatch("hideEditRolesAndPermissions");
    },
    submit() {
      this.$Progress.start();
      const selectedPermissions = this.selectedPermissions.filter((permission) => permission);
      this.$store.dispatch("updateUserPermissions", {
        permissions: selectedPermissions,
      });
    },
  },
}
</script>

<style>

</style>