<template>
  <template v-if="form">
    <Content :search="false">
      <template v-slot:beforeSearch>
        <div class="flex items-center text-lg font-semibold dark:text-gray-300">
          <Button icon="arrow-left" @click="goBack" />

          <template v-if="user">
            <h2>Labot lietotāju {{ user.name }}</h2>
          </template>
        </div>
      </template>

      <template v-slot:content>
        <template v-if="inputData">
          <div class="w-full divide-y divide-gray-200 dark:divide-gray-700 max-w-3xl mx-auto">
            <form class="space-y-6 w-full">

              <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div class="col-span-1">
                  <Input name="name" placeholder="Lietotāja vārds" v-model="form.name" :errors="errors.name" />
                </div>

                <div class="col-span-1">
                  <Input name="email" placeholder="E-pasts" v-model="form.email" :errors="errors.email" />
                </div>
              </div>

              <div>
                <div class="flex justify-end">
                  <button @click="goBack" type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2">
                    Atcelt
                  </button>

                  <template v-if="!loading">
                    <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
                      Labot
                    </button>
                  </template>
                  <template v-else>
                    <Loading />
                  </template>
                </div>
              </div>
            </form>

            <template v-if="user.role">
              <div class="sm:rounded-md mb-3 mt-6">
                <!-- User Role -->
                <ul role="list" class="">
                  <item-card>
                    <template v-slot:title>
                      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300 mb-2 md:mb-0">
                        Loma
                      </h3>
                    </template>
                    <template v-slot:buttons>

                    <Button icon="pencil" @click="showEditRoleForm" />

                    </template>

                    <template v-slot:content>
                      <template v-if="!showEditRole">
                        <ItemText :title="user.role.display_name" />
                      </template>

                      <template v-else>
                        <EditUserRole :roles="inputData.roles" />
                      </template>
                    </template>
                  </item-card>
                </ul>

                <!-- User Role permissions-->
                <template v-if="user.permissions.permissions_via_role.length > 0 && !showEditRole">
                  <ul role="list" class="">
                    <item-card>
                      <template v-slot:title>
                        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300 mb-2 md:mb-0">
                          Lomas atļaujas
                        </h3>
                      </template>

                      <template v-slot:content>
                        <template v-for="permission in user.permissions.permissions_via_role" :key="permission.id">
                          <div class="w-full sm:w-1/2">
                            <ItemText :title="permission.display_name" />
                          </div>
                        </template>
                      </template>
                    </item-card>
                  </ul>
                </template>
              </div>

              <div class="sm:rounded-md mb-3 mt-6">
                <!-- User additional Permissions -->
                <ul role="list" class="">
                  <item-card>
                    <template v-slot:title>
                      <template v-if="user.permissions.direct_permissions.length > 0">
                        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300 mb-2 md:mb-0">
                          Piešķirtās papildus atļaujas atļaujas
                        </h3>
                      </template>
                    </template>
                    <template v-slot:buttons>
                      <template v-if="user.permissions.direct_permissions.length > 0 && !showEditPermissions">
                        <Button icon="pencil" @click="showEditPermissionsForm" />
                      </template>
                    </template>

                    <template v-slot:content>
                      <template v-if="!showEditPermissions">

                        <template v-if="user.permissions.direct_permissions.length > 0">
                          <template v-for="permission in user.permissions.direct_permissions" :key="permission.id">
                            <div class="w-full sm:w-1/2">
                              <ItemText :title="permission.display_name" />
                            </div>
                          </template>
                        </template>

                        <template v-else>
                          <div class="w-full">
                            <p class="text-center text-lg text-gray-400 mb-3">Papildus atļaujas nav piešķirtas</p>

                            <template v-if="availableAdditionalPermissions.length > 0">
                              <button @click="showEditPermissionsForm" type="button" class="block mx-auto items-center px-3 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 hover:bg-gray-100 transition duration-500 ease-in-out">
                                Pievienot
                              </button>
                            </template>
                          </div>
                        </template>
                      </template>

                      <template v-else>
                        <EditUserPermissions :inputData="inputData" :userPermissions="user.permissions" />
                      </template>
                    </template>
                  </item-card>
                </ul>
              </div>

            </template>
          </div>
        </template>
      </template>
    </Content>
  </template>
</template>

<script>
import {mapGetters} from "vuex";
import Input from "@/components/Components/Input";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import ItemCard from "@/components/Components/ItemCard";
import ItemText from "@/components/Components/ItemText";
import EditUserRole from "@/components/Users/EditUserRole"
import EditUserPermissions from "@/components/Users/EditUserPermissions"

export default {
  name: "EditUser",
  components: {
    Input,
    Loading,
    ItemCard,
    ItemText,
    EditUserRole,
    EditUserPermissions,
  },
  data: () => ({
    additionalPermissions: [],
  }),
  created() {
    this.$store.dispatch("getUserForEdit", this.$route.params.userId);
    this.$store.dispatch("hideEditRolesAndPermissions");
  },
  computed: {
    ...mapGetters({
      user: "selectedUserForEdit",
      inputData: "userInputData",
      errorMessages: "userErrorMessages",
      showEditRole: "showEditRole",
      showEditPermissions: "showEditPermissions",
      loading: "loading",
      errors: "errors",
    }),
    form() {
      return this.user
    },
    availableAdditionalPermissions() {
      return this.inputData.all_permissions.filter((permission) => {
        return !this.user.permissions.permissions_via_role.some(
          (rolePermission) => {
            return permission.id === rolePermission.id;
          }
        );
      });
    },
    formValidation() {
      return {
        name: {
          rules: ['required']
        },
        email: {
          rules: ['required', 'email']
        },
      }
    }
  },
  methods: {
    goBack() {
      this.$store.dispatch("clearUserErrorMessages");
      this.$router.go(-1)
    },
    showEditRoleForm() {
      this.$store.dispatch("showEditRole");
    },
    showEditPermissionsForm() {
      this.$store.dispatch("showEditPermissions");
    },
    submit() {
      this.$Progress.start();
      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch("updateUser");
      } else {
        this.$Progress.fail();
      }
    },
  },
}
</script>