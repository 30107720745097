class Validators {
  static validate(value) {
    const errors = {};
    errors.required = this.isEpmty(value);
    errors.required_array = this.isEpmtyArray(value);
    errors.email = this.isEmail(value);
    errors.minVal0 = this.minVal0(value);
    return errors;
  }

  static isEpmty(fieldValue) {
    if (fieldValue && typeof fieldValue == "object") {
      return fieldValue.empty ? "Šis lauks ir obligāts" : "";
    } else {
      return fieldValue !== 0 && !fieldValue ? "Šis lauks ir obligāts" : "";
    }
  }

  static isEpmtyArray(fieldValue) {
    if (typeof fieldValue == "object") {
      return !fieldValue || fieldValue.length < 1
        ? "Šis lauks ir obligāts"
        : "";
    }
  }

  static minVal0(fieldValue) {
    if (typeof fieldValue == "number") {
      return fieldValue > 0
        ? ""
        : "Vērībai jābūt lielākai par 0";
    }
  }

  static isEmail(fieldValue) {
    if (fieldValue) {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !re.test(fieldValue) ? "Lūdzu ievadiet derīgu e-pasta adresi" : "";
    } else {
      return null;
    }
  }
}

export default Validators;
